import "./App.css";
import { Tabs } from "@mantine/core";
import { MantineProvider } from "@mantine/core";
import { Divider } from "@mantine/core";
import { useState } from "react";
import { TextInput, Loader, Button, Chip } from "@mantine/core";
import { getHotkeyHandler } from "@mantine/hooks";

import styled from "@emotion/styled";
const TabHeader = styled.div`
  padding-left: 1em;
  font-weight: bold;
  font-style: italic;

  &:first-of-type {
    padding-top: 3em;
  }
`;

const ButtonHolder = styled.div`
  position: relative;
  left: -2em;
`;

const Examples = styled.div`
  display: flex;
  column-gap: 2px;
  row-gap: 2px;
  flex-flow: row wrap;
  width: 900px;
`;

function App() {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <Tabs defaultValue="search" variant="pills" orientation="vertical">
        <Tabs.List>
          <TabHeader>AI Experiments</TabHeader>
          <Tabs.Tab value="about">Intro</Tabs.Tab>
          <Tabs.Tab value="aboutme">About Me</Tabs.Tab>
          <TabHeader>Demos</TabHeader>
          <Tabs.Tab value="search">Search with Citations</Tabs.Tab>
          {/* <Tabs.Tab value="code">Find the relevant code</Tabs.Tab>
          <Tabs.Tab value="photo">Photoshop for ordinary people</Tabs.Tab>
          <Tabs.Tab value="tshirt">Make Custom Clothing</Tabs.Tab> */}
        </Tabs.List>

        <Tabs.Panel value="about" pt="xs">
          <h1>Hi!</h1>

          <p>
            I've put together a bunch of demo ideas here which I think could be
            turned into products. I couldn't decide on one particular idea, so
            I've made a bunch of experiments which I've put here along with some
            thoughts on how they could be expanded ito companies and I would
            love an advisor to help narrow down into some great products.
          </p>

          <p>
            Thanks for taking the time to look at the demos, and for coming up
            with the aigrant scheme which if nothing else is inspiration to look
            into this really interesting area!
          </p>

          <h2>Have fun, and I look forward to hearing back from you</h2>
        </Tabs.Panel>

        <Tabs.Panel value="aboutme" pt="xs">
          <h1>About me</h1>

          <h2>
            TLDR - I am a creative maker of things and love the tinkering that
            comes with engineering and startups
          </h2>

          <p>
            While at university I started a society which made AI drones, and
            took the team to a competition where we needed to navigate
            autonomously to retrieve a USB stick from a building.
          </p>

          <p>
            After gradating I was in the second cohort of Entrepreneur First
            where I started a company to make toy robots to teach kids
            programming and launched a successful kickstarter campaign.{" "}
          </p>

          <p>
            I then worked at a consultancy where I helped to build websites that
            were viewed by millions.
          </p>

          <p>
            I then joined an AI camera monitoring startup where we made sensors
            that could detect traffic movement in real time. I built a cluster
            that could cope with 100,000’s of hours of video being uploaded and
            made a web app to allow all of those videos to be visualised
            interactively.
          </p>

          <p>
            I went travelling and worked on a bunch of projects including a fish
            counting AI and a system to read badly scanned company accounts to
            gather insights on which companies might be looking for a new
            investments.
          </p>

          <p>
            I then worked at a self driving car company and helped set up large
            amounts of their infrastructure to allow fast training and CI so the
            researchers could get on with their job of creating great research.
          </p>

          <p>
            I worked at an AI drug discovery company and built a system to store
            and search billions of generated designs and allow chemists to
            quickly select the most promising avenues for their next compounds.
          </p>

          <p>
            Most recently I’ve set up an underwater rugby team in Oxford, built
            an extension to my house and am building a tool (at meetsemper.com)
            to help private companies allow their employees to sell their equity
            to investors before they go public.
          </p>

          <h2>
            The description of aigrants of "technical &amp; pragmatic founders
            who want to build great products" really resonated with me. I want
            to help make the future, and it feels like (at least part of) the
            future is in AI{" "}
          </h2>
        </Tabs.Panel>

        <SearchWithReferences />

        <Tabs.Panel value="code" pt="xs">
          Code
        </Tabs.Panel>

        <Tabs.Panel value="photo" pt="xs">
          Photo
        </Tabs.Panel>

        <Tabs.Panel value="tshirt" pt="xs">
          T-shirt
        </Tabs.Panel>
      </Tabs>
    </MantineProvider>
  );
}

export default App;

const SearchWithReferences = () => {
  const [input, setInput] = useState();
  const [answer, setAnswer] = useState({});
  const [loading, setLoading] = useState(false);

  const ask = async () => {
    setLoading(true);

    setAnswer(
      await fetch(
        "https://us-central1-aiexperiments-718a4.cloudfunctions.net/widgets/searchWithCitations",
        {
          body: input,
          method: "post",
        }
      ).then((r) => r.json())
    );
    setLoading(false);
  };

  const rightSection = loading ? (
    <Loader size="xs" />
  ) : (
    <ButtonHolder>
      <Button onClick={() => ask()}>Ask</Button>
    </ButtonHolder>
  );
  return (
    <Tabs.Panel value="search" pt="xs">
      <h2>Recorded example (in case the demo breaks)</h2>
      <iframe
        width="420"
        height="315"
        src="https://www.youtube.com/embed/RrPZTY_oLvs"
      ></iframe>
      <Divider my="sm" />
      <h2>Play with it live (be nice, there will be rough edges)</h2>
      <h3>Ask me a question?</h3>
      <TextInput
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Type here to ask a question"
        radius="md"
        size="xl"
        rightSection={rightSection}
        onKeyDown={getHotkeyHandler([["Enter", ask]])}
      />

      <h4>Some examples to try</h4>
      <Examples>
        <Chip
          onClick={() => setInput("What is the size of brussels?")}
          checked={false}
        >
          What is the size of brussels?
        </Chip>
        <Chip
          onClick={() => setInput("How long did queen elizabeth reign for?")}
          checked={false}
        >
          How long was queen elizabeth queen for?
        </Chip>
        <Chip
          onClick={() => setInput("Where was the first tranistor made?")}
          checked={false}
        >
          Where was the first tranistor made?
        </Chip>
        <Chip
          onClick={() => setInput("when does season 15 of ncis come out?")}
          checked={false}
        >
          when does season 15 of ncis come out?
        </Chip>
        <Chip
          onClick={() => setInput("Who was the first man on the moon?")}
          checked={false}
        >
          Who was the first man on the moon?
        </Chip>
      </Examples>
      {loading ? null : <Answer answer={answer} />}
      <Divider my="sm" />
      <h2>Where I want to take it next</h2>
      <p>
        I think one of the annoying things about oracle models is that they can
        sound convincing, by can also be totally wrong. I think using them to
        create a search with a reference to a place you can verify the
        information is very powerful. This is similar in principle to similar to
        the demo on https://openai.com/blog/webgpt/ but I thought it was
        important to surface the citations more clearly, so it is more obvious
        when they are wrong.
      </p>
      <p>
        This is obviously early stage, but it already answers some questions
        better than gtp3. For example it knows that eurovision is being held in
        the United Kingdom, rather than lying and claiming that it being held in
        france. It is much worse at answering questions that are more freeform
        and therefore not capable of being answered from one wikipedia article.
      </p>
      <p>
        This is fairly similar to what google search already does, but it's cool
        that you can get better answers than google with a much smaller budget!
      </p>
      <p>
        I think the key way that this could be commerialised is by applying to
        datasets which weren't wikipedia, but were internal private databases.
        E.g. can you fine-tune this to have a clever search of your company
        wiki.
      </p>
    </Tabs.Panel>
  );
};

const Answer = ({ answer }) => {
  if (!answer.answer) {
    return null;
  } else {
    return (
      <>
        <h3>The answer is {answer.answer}</h3>

        {answer.summary
          .slice(2)
          .split(/\n\n[0-9+]:/)
          .map((x) => (
            <p
              dangerouslySetInnerHTML={{
                __html: x.replace(answer.quote, `<b>${answer.quote}</b>`),
              }}
            />
          ))}

        <h4>
          Full article{" "}
          <a
            href={`https://en.wikipedia.org/wiki/${
              answer.title
            }#:~:text=${encodeURIComponent(answer.quote)}`}
          >
            link
          </a>
        </h4>
        <iframe
          id="inlineFrameExample"
          title="Inline Frame Example"
          width="100%"
          height="400"
          src={`https://en.wikipedia.org/wiki/${
            answer.title
          }#:~:text=${encodeURIComponent(answer.quote)}`}
        ></iframe>

        <h4>For reference the raw gtp3 answer was</h4>
        <p>{answer.rawGtpAnswer}</p>
      </>
    );
  }
};
